import React from 'react'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import PostCard from '../components/elements/PostCard'
import { graphql } from 'gatsby'



const CategoryTemplate = props => {
    const { posts } = props.data.allContentfulPost
    return (
        <Layout
            header={props.data.categories.nodes[0].name}
            title={`${props.data.categories.nodes[0].name} Posts`}
            path={props.path}
        >
            { posts.length > 0 ? 
            posts.map(({
                title, slug, subtitle, createdAt, coverImage
            }) => <PostCard key={slug} header={title} linkTo={`/posts/${slug}`} subHeader={subtitle} dateString={createdAt} coverImage={coverImage}/>
            
            ): "" }

        </Layout>
    )
}

export const SectionQuery = graphql`
    query Section($id: String!, $name: String!) {
        categories: allContentfulCategory(filter: {id: {eq: $id}}) {
            nodes {
                name
                slug
            }
        }
        allContentfulPost(filter: {category: {name: {eq: $name}}}) {
            posts: nodes {
                childContentfulPostBodyRichTextNode {
                    json
                }
                subtitle
                title
                slug
                createdAt
                tags
                coverImage {
                    fluid(maxWidth: 600, quality: 80) {
                        ...GatsbyContentfulFluid
                    }
                }
            }
        }

    }
`

CategoryTemplate.propTypes = {
    posts: PropTypes.array
}

export default CategoryTemplate
